import { ButtonLink } from '@landler/tw-component-library';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { OrganizationTypeEnum } from '@/api/rest/resources/types/organization';
import { Project } from '@/api/rest/resources/types/project';
import { SuspenseBoundary } from '@/components';
import { useRequireMembershipType } from '@/hooks/useMembershipType';
import { useProjects } from '@/pages/shared/hooks/useProjects';
import { paths } from '@/routing';

import { ProjectCard } from './components/projectCard/ProjectCard';

export const ProjectsList = () => {
  const { t } = useTranslation();
  const requireLandstewardMembership = useRequireMembershipType('land_steward');

  const projects = useProjects().data.results;

  const sortedProjects = useMemo(
    () =>
      projects?.sort((project1, project2) => {
        if (
          project1.organization.type === OrganizationTypeEnum.DEMO &&
          project2.organization.type !== OrganizationTypeEnum.DEMO
        ) {
          return -1;
        }

        if (
          project1.organization.type !== OrganizationTypeEnum.DEMO &&
          project2.organization.type === OrganizationTypeEnum.DEMO
        ) {
          return 1;
        }

        return 0;
      }),
    [projects],
  );

  const projectCountLabel = useMemo(() => {
    const demoProjectsCount = (projects as Project[])?.filter(
      (project) => project.organization.type === OrganizationTypeEnum.DEMO,
    ).length;
    const regularProjectsCount = (projects as Project[])?.filter(
      (project) => project.organization.type !== OrganizationTypeEnum.DEMO,
    ).length;

    // regular projects + demo projects
    if (regularProjectsCount > 0 && demoProjectsCount > 0) {
      return `${t('shared.projects.projectsCount', { count: regularProjectsCount })} (${t(
        'shared.projects.demoProjectsCount',
        {
          count: demoProjectsCount,
        },
      )})`;
    }

    // demo projects only
    if (demoProjectsCount > 0) {
      return t('shared.projects.demoProjectsCount', { count: demoProjectsCount });
    }

    // regular projects only (or fallback if projects are not loaded yet)
    return t('shared.projects.projectsCount', { count: regularProjectsCount });
  }, [projects, t]);

  return (
    <div data-testid='projects-list' data-cy='projects-list'>
      {(() => {
        if (!sortedProjects) {
          return null;
        }

        if (sortedProjects.length < 1) {
          return (
            <div className='typography-h1 mt-20 text-center text-text-disabled'>{t('shared.projects.noProjects')}</div>
          );
        }

        return (
          <>
            <div className='flex items-center justify-between pb-6'>
              <span className='typography-overline' data-cy='project-count'>
                {projectCountLabel}
              </span>
              {requireLandstewardMembership(
                <ButtonLink to={paths.landSteward.newProject} data-cy='add-project-button'>
                  {t('shared.projects.labels.createProjectButton')}
                </ButtonLink>,
              )}
            </div>
            <SuspenseBoundary>
              <div className='flex flex-col gap-6 animate-in fade-in slide-in-from-bottom-4'>
                {sortedProjects.map((project) => (
                  <ProjectCard key={project.id} project={project} />
                ))}
              </div>
            </SuspenseBoundary>
          </>
        );
      })()}
    </div>
  );
};
