import {
  Button,
  ButtonLink,
  ButtonLinkProps,
  ButtonProps,
  ConfirmationDialog,
  Dropdown,
  DropdownContent,
  DropdownItem,
  DropdownItemLink,
  DropdownItemLinkProps,
  DropdownItemProps,
  DropdownTrigger,
  IconButton,
  RiAddFill,
  RiEditLine,
  RiMore2Fill,
  Stack,
  toast,
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from '@landler/tw-component-library';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import { ProjectBuyerDetail, ProjectStatus } from '@/api/rest/resources/types/project';
import { CopyLandStewardEmailButton, SecondaryNav } from '@/components';
import { UNANALYZABLE_PLOT_STATUS_TYPES } from '@/config/constants';
import { useDisplayNumber } from '@/hooks/useDisplayNumber';
import { useMembershipType } from '@/hooks/useMembershipType';
import { useScreenSize } from '@/hooks/useScreenSize';
import { useAnalyseProject } from '@/pages/landsteward/hooks/useAnalyseProject';
import { useDashboardByProjectId } from '@/pages/shared/hooks/useDashboardByProjectId';
import { usePlotAggregate } from '@/pages/shared/hooks/usePlotAggregate';
import { usePlotsForProject } from '@/pages/shared/hooks/usePlotsForProject';
import { useProjectDetailById } from '@/pages/shared/hooks/useProjectDetailById';
import { useProjectId } from '@/pages/shared/hooks/useProjectId';
import { paths } from '@/routing';
import { buildPath } from '@/utils/buildPath';
import { getProjectPermissions } from '@/utils/permissions/getProjectPermissions';
import { squareMetersToHectares } from '@/utils/plot';

import { EditProject } from './EditProject';

export const ProjectSecondaryNav = () => {
  const { t } = useTranslation();
  const isLargeScreen = useScreenSize() === 'large';
  const membershipType = useMembershipType();
  const projectId = useProjectId();
  const projectDetail = useProjectDetailById(projectId).data;
  const hasWritePermission = getProjectPermissions(projectDetail).includes('write');

  const plots = usePlotsForProject().data.results;
  const isAnalysable = projectDetail.status === ProjectStatus.ready_to_analyse && hasWritePermission;
  const [showAnalyseProjectConfirmation, setShowAnalyseProjectConfirmation] = useState(false);

  const { submit, isSubmitting } = useAnalyseProject();

  const analyseProject = async () => {
    try {
      setShowAnalyseProjectConfirmation(true);
      await submit();

      toast({
        type: 'success',
        title: t('landSteward.projects.nav.analyseProject.toasts.success'),
      });
    } catch (error) {
      toast({
        type: 'error',
        title: t('landSteward.projects.nav.analyseProject.toasts.error'),
      });
    } finally {
      setShowAnalyseProjectConfirmation(false);
    }
  };

  const dashboardData = useDashboardByProjectId().data;
  const lastAnalysedAt = dashboardData.project_last_fact_measured_at;
  const translationArgs = {
    projectName: projectDetail.name,
    numPlots: plots.length,
    area: useDisplayNumber(squareMetersToHectares(projectDetail.area)),
  };

  const [isEditingProject, setIsEditingProject] = useState(false);

  return (
    <>
      <SecondaryNav
        backPath={
          membershipType === MembershipWithOrganizationTypeEnum.land_steward
            ? paths.landSteward.projects
            : paths.buyer.projects
        }
        title={projectDetail.name}
        action={
          membershipType === MembershipWithOrganizationTypeEnum.land_steward ? (
            <>
              {isLargeScreen ? (
                <Stack direction='row' className='justify-between' spacing={6}>
                  <AddPlotsButton
                    to={buildPath(paths.landSteward.newPlot, { pathParams: { projectId } })}
                    leftAdornment={<RiAddFill />}
                    disabled={!hasWritePermission}
                    className='self-center'
                  />
                  <AnalyseProjectButton
                    onClick={() => setShowAnalyseProjectConfirmation(true)}
                    disabled={!isAnalysable}
                  />
                  <Dropdown>
                    <DropdownTrigger asChild>
                      <IconButton
                        className='h-fit border border-primary-100 text-primary-100'
                        data-testid='overview-more-button'
                      >
                        <RiMore2Fill size={24} />
                      </IconButton>
                    </DropdownTrigger>
                    <DropdownContent align='end'>
                      <EditProjectDropdownItem
                        onSelect={() => setIsEditingProject(true)}
                        disabled={!hasWritePermission}
                      />
                    </DropdownContent>
                  </Dropdown>
                </Stack>
              ) : (
                <Dropdown modal>
                  <DropdownTrigger asChild>
                    <IconButton
                      className='border border-primary-100 text-primary-100'
                      data-testid='overview-more-button'
                    >
                      <RiMore2Fill size={24} />
                    </IconButton>
                  </DropdownTrigger>
                  <DropdownContent align='end'>
                    <AddPlotsDropdownItem
                      to={buildPath(paths.landSteward.newPlot, { pathParams: { projectId } })}
                      disabled={!hasWritePermission}
                    />
                    <AnalyseProjectDropdownItem
                      onSelect={() => setShowAnalyseProjectConfirmation(true)}
                      disabled={!isAnalysable}
                    />
                    <EditProjectDropdownItem
                      onSelect={() => setIsEditingProject(true)}
                      disabled={!hasWritePermission}
                    />
                  </DropdownContent>
                </Dropdown>
              )}
              <EditProject
                projectId={projectDetail.id}
                open={isEditingProject}
                onOpenChange={(open) => setIsEditingProject(open)}
              />
            </>
          ) : (
            <CopyLandStewardEmailButton email={(projectDetail as ProjectBuyerDetail).organization_email} />
          )
        }
      />
      <ConfirmationDialog
        data-testid='analyse-project'
        open={showAnalyseProjectConfirmation}
        onOpenChange={setShowAnalyseProjectConfirmation}
        header={
          lastAnalysedAt
            ? t('landSteward.projects.nav.analyseProject.dialog.title')
            : t('landSteward.projects.nav.analyseProject.dialog.newAnalysis.title')
        }
        body={
          lastAnalysedAt
            ? t('landSteward.projects.nav.analyseProject.dialog.message', translationArgs)
            : t('landSteward.projects.nav.analyseProject.dialog.newAnalysis.message', translationArgs)
        }
        PrimaryAction={
          <Button variant='contained' loading={isSubmitting} onClick={analyseProject}>
            {t('landSteward.projects.nav.analyseProject.dialog.submitButton')}
          </Button>
        }
        CancelAction={t('global.ui.buttons.cancel')}
      />
    </>
  );
};

const AnalyseProjectButton: React.FC<ButtonProps> = (delegated) => {
  const { t } = useTranslation();
  const analyseProjectLabel = t('landSteward.projects.nav.analyseProject.title');

  const projectId = useProjectId();
  const projectDetail = useProjectDetailById(projectId).data;
  const hasWritePermission = getProjectPermissions(projectDetail).includes('write');
  const analysisInProgress = projectDetail.status === ProjectStatus.scheduled_for_analysis;

  const plots = usePlotsForProject().data.results;
  const incompletePlots = plots.filter((plot) => UNANALYZABLE_PLOT_STATUS_TYPES.includes(plot.status)).length;
  const { plots_requiring_attention: invalidPlotsCount } = usePlotAggregate().data;
  const numPlots = invalidPlotsCount === 0 ? incompletePlots : invalidPlotsCount;

  if (!delegated.disabled) {
    return (
      <Button data-cy='analyse-project' {...delegated}>
        {analyseProjectLabel}
      </Button>
    );
  }

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button data-cy='analyse-project' {...delegated}>
          {analyseProjectLabel}
        </Button>
      </TooltipTrigger>
      {hasWritePermission ? (
        <TooltipContent sideOffset={5} side='bottom'>
          {analysisInProgress
            ? t('landSteward.projects.nav.analyseProject.tooltips.analysisInProgress')
            : t('landSteward.projects.nav.analyseProject.tooltips.disabledAnalyseProject', {
                numPlots,
              })}
          <TooltipArrow className='fill-white-100' />
        </TooltipContent>
      ) : (
        <TooltipContent sideOffset={5} side='bottom'>
          {t('global.tooltips.triggerAnalysisDisabledLockedProject')}
          <TooltipArrow className='fill-white-100' />
        </TooltipContent>
      )}
    </Tooltip>
  );
};

const AnalyseProjectDropdownItem: React.FC<DropdownItemProps> = (delegated) => {
  const { t } = useTranslation();
  const analyseProjectLabel = t('landSteward.projects.nav.analyseProject.title');

  const projectId = useProjectId();
  const projectDetail = useProjectDetailById(projectId).data;
  const hasWritePermission = getProjectPermissions(projectDetail).includes('write');

  const plots = usePlotsForProject().data.results;
  const incompletePlots = plots.filter((plot) => UNANALYZABLE_PLOT_STATUS_TYPES.includes(plot.status)).length;
  const { plots_requiring_attention: invalidPlotsCount } = usePlotAggregate().data;
  const numPlots = invalidPlotsCount === 0 ? incompletePlots : invalidPlotsCount;

  if (!delegated.disabled) {
    return <DropdownItem {...delegated}>{analyseProjectLabel}</DropdownItem>;
  }

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <DropdownItem {...delegated}>{analyseProjectLabel}</DropdownItem>
      </TooltipTrigger>
      <TooltipContent sideOffset={5} side='left'>
        {hasWritePermission
          ? t('landSteward.projects.nav.analyseProject.tooltips.disabledAnalyseProject', { numPlots })
          : t('global.tooltips.triggerAnalysisDisabledLockedProject')}
        <TooltipArrow className='fill-white-100' />
      </TooltipContent>
    </Tooltip>
  );
};

const AddPlotsButton: React.FC<ButtonLinkProps> = ({ to, ...delegated }) => {
  const { t } = useTranslation();

  if (!delegated.disabled) {
    return (
      <ButtonLink type='button' variant='outline' to={to} {...delegated}>
        {/* eslint-disable-next-line sonarjs/no-duplicate-string */}
        {t('shared.ncaDetail.buttons.addPlots')}
      </ButtonLink>
    );
  }

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button {...delegated} variant='outline'>
          {t('shared.ncaDetail.buttons.addPlots')}
        </Button>
      </TooltipTrigger>
      <TooltipContent sideOffset={5} side='left'>
        {t('global.tooltips.addPlotsDisabledLockedProject')}
        <TooltipArrow className='fill-white-100' />
      </TooltipContent>
    </Tooltip>
  );
};

const AddPlotsDropdownItem: React.FC<DropdownItemLinkProps> = ({ to, ...delegated }) => {
  const { t } = useTranslation();

  if (!delegated.disabled) {
    return (
      <DropdownItemLink to={to} {...delegated}>
        {t('shared.ncaDetail.buttons.addPlots')}
      </DropdownItemLink>
    );
  }

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <DropdownItem {...delegated}>{t('shared.ncaDetail.buttons.addPlots')}</DropdownItem>
      </TooltipTrigger>
      <TooltipContent sideOffset={5} side='left'>
        {t('global.tooltips.addPlotsDisabledLockedProject')}
        <TooltipArrow className='fill-white-100' />
      </TooltipContent>
    </Tooltip>
  );
};

const EditProjectDropdownItem: React.FC<DropdownItemProps> = (delegated) => {
  const { t } = useTranslation();

  const isLargeScreen = useScreenSize() === 'large';
  const leftAdornment = isLargeScreen ? <RiEditLine /> : null;

  if (!delegated.disabled) {
    return (
      <DropdownItem leftAdornment={leftAdornment} {...delegated}>
        {t('shared.projects.overviewCard.labels.editProject')}
      </DropdownItem>
    );
  }

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <DropdownItem leftAdornment={leftAdornment} {...delegated}>
          {t('shared.projects.overviewCard.labels.editProject')}
        </DropdownItem>
      </TooltipTrigger>
      <TooltipContent sideOffset={5} side='left'>
        {t('global.tooltips.disabledLockedProject')}
        <TooltipArrow className='fill-white-100' />
      </TooltipContent>
    </Tooltip>
  );
};
