import { OrganizationTypeEnum } from '@/api/rest/resources/types/organization';
import { Project } from '@/api/rest/resources/types/project';
import { UNEDITABLE_PROJECT_TYPES } from '@/config/constants';

export type ProjectPermission = 'read' | 'write';

// TODO: think about renaming this to not confuse it with role-based only permissions
export const getProjectPermissions = (project: Project): ProjectPermission[] => {
  // demo projects
  if (project.organization.type === OrganizationTypeEnum.DEMO) {
    return ['read'];
  }

  // locked projects
  if (project.is_locked) return ['read'];

  // project states
  if (UNEDITABLE_PROJECT_TYPES.includes(project.status)) return ['read'];

  return ['read', 'write'];
};
