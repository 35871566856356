import { useQuery } from '@tanstack/react-query';

import { getRequest } from '@/api/rest/hooks/requests/get';
import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import {
  GET_BUYER_PROJECTS_PATH,
  GET_PROJECT_PATH,
  GetBuyerProjectsErrorDto,
  GetBuyerProjectsRequestPathVariables,
  GetBuyerProjectsRequestQueryParams,
  GetBuyerProjectsSuccessDto,
  GetProjectErrorDto,
  GetProjectRequestPathVariables,
  GetProjectRequestQueryParams,
  GetProjectSuccessDto,
  Project,
} from '@/api/rest/resources/types/project';
import { useUser } from '@/lib/auth';

type SuccessDTO = Project[];
type ErrorDTO = GetProjectErrorDto | GetBuyerProjectsErrorDto;

export const useGetProjects = () => {
  const user = useUser();

  const isLandSteward = user.membership.type === MembershipWithOrganizationTypeEnum.land_steward;

  const landStewardQueryParams = {
    organization: user.membership.organization.id,
  };

  const buyerQueryParams = {
    buyer: user.membership.organization.id,
  };

  const landstewardQueryFn = async () => {
    return (
      await getRequest<GetProjectSuccessDto, GetProjectRequestPathVariables, GetProjectRequestQueryParams>(
        GET_PROJECT_PATH,
        undefined,
        landStewardQueryParams,
      )
    ).results;
  };

  const buyerQueryFn = async () => {
    return (
      await getRequest<
        GetBuyerProjectsSuccessDto,
        GetBuyerProjectsRequestPathVariables,
        GetBuyerProjectsRequestQueryParams
      >(GET_BUYER_PROJECTS_PATH, undefined, buyerQueryParams)
    ).results;
  };

  const landstewardQueryKey = ['land_steward', 'project', landStewardQueryParams];

  const buyerQueryKey = ['buyer', 'project', buyerQueryParams];

  return useQuery<SuccessDTO, ErrorDTO>({
    queryKey: isLandSteward ? landstewardQueryKey : buyerQueryKey,
    queryFn: isLandSteward ? landstewardQueryFn : buyerQueryFn,
    staleTime: 1000 * 60 * 5,
    throwOnError: true,
  });
};
