import { Button, Text } from '@landler/component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '@/lib/auth';
import { paths } from '@/routing';

import { ActivateAccountPageContent } from '../page-content/PageContent';

export const ActivateAccountSuccess: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { isSignedIn } = useAuth();

  return (
    <ActivateAccountPageContent headlineText={t('activateAccount.success.title')} dataCy='activate-account'>
      <Text variant='body1' data-cy='activate-account-text'>
        {t('activateAccount.success.text')}
      </Text>
      <Button
        href={isSignedIn ? paths.landSteward.projects : paths.login}
        size='large'
        sx={{ alignSelf: 'end' }}
        onClick={(e) => {
          e.preventDefault();
          navigate(isSignedIn ? paths.landSteward.projects : paths.login);
        }}
        data-cy='activate-account-button'
      >
        {isSignedIn ? t('activateAccount.success.continueToProjects') : t('activateAccount.success.continueToLogin')}
      </Button>
    </ActivateAccountPageContent>
  );
};
