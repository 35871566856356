import {
  cn,
  Container,
  Dropdown,
  DropdownContent,
  DropdownItem,
  DropdownItemLink,
  DropdownItemLinkProps,
  DropdownItemProps,
  DropdownTrigger,
  IconButton,
  RiDeleteBinLine,
  RiEditLine,
  RiMore2Fill,
  Stack,
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from '@landler/tw-component-library';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import { Plot } from '@/api/rest/resources/types/plot';
import { SecondaryNav } from '@/components';
import {
  NCCard,
  NCCard2SectionLayout,
  NCCardAnalysisTypeToggle,
  NCCardComplianceFooter,
  NCCardHeader,
} from '@/components/NCCard/NCCard';
import { BiodiversityExplainer } from '@/components/NCExplainers/BiodiversityExplainer';
import { CarbonExplainer } from '@/components/NCExplainers/CarbonExplainer';
import { WaterExplainer } from '@/components/NCExplainers/WaterExplainer';
import { BetaPill } from '@/components/Pill/BetaPill';
import { COMPLIANCE_LOGOS_BIODIVERSITY, COMPLIANCE_LOGOS_CARBON, COMPLIANCE_LOGOS_WATER } from '@/config/constants';
import { useMembershipType } from '@/hooks/useMembershipType';
import { useScreenSize } from '@/hooks/useScreenSize';
import { SignedInPageLayout } from '@/layout/signed-in-page';
import { useMenuItems } from '@/pages/shared/hooks/useMenuItems';
import { usePlot } from '@/pages/shared/hooks/usePlot';
import { useProjectDetailById } from '@/pages/shared/hooks/useProjectDetailById';
import { paths } from '@/routing';
import { buildPath } from '@/utils/buildPath';
import { getProjectPermissions } from '@/utils/permissions/getProjectPermissions';

import { DeletePlotWithConfirmation } from '../plots/components/DeletePlotWithConfirmation';
import { BiodiversityGraphTile } from './components/biodiversity/GraphTile';
import { BiodiversityStatsTile } from './components/biodiversity/StatsTile';
import { CarbonGraphTile } from './components/carbon/GraphTile';
import { CarbonStatsTile } from './components/carbon/StatsTile';
import { PlotMap } from './components/PlotMap';
import { PlotOverview } from './components/PlotOverview';
import { WaterHistoricalGraphTile } from './components/water/HistoricalGraphTile';
import { WaterHistoricalStatsTile } from './components/water/HistoricalStatsTile';

export const PlotPage = () => {
  const { t } = useTranslation();
  const isSmallScreen = useScreenSize() === 'small';

  const plot = usePlot().data;
  const membershipType = useMembershipType();
  const location = useLocation();

  const projectDetail = useProjectDetailById().data;
  const projectId = projectDetail.id;

  const menuItems = useMenuItems();

  return (
    <SignedInPageLayout items={menuItems} withCustomContentContainer>
      <SecondaryNav
        title={plot.name}
        backPath={
          membershipType === MembershipWithOrganizationTypeEnum.land_steward
            ? buildPath(paths.landSteward.plots, {
                pathParams: { projectId },
                queryParams: { page: location.state?.plotPage },
              })
            : buildPath(paths.buyer.plots, {
                pathParams: { projectId },
                queryParams: { page: location.state?.plotPage },
              })
        }
        action={membershipType === MembershipWithOrganizationTypeEnum.land_steward ? <PlotActions /> : null}
      />

      <Container gutterWidth={{ sm: 4 }} className='my-10'>
        <div className={cn('grid grid-rows-[auto] gap-10', 'md:w-auto md:grid-cols-2')}>
          <PlotMap />
          <PlotOverview />
        </div>
        <Stack spacing={8} className='mt-10'>
          <span className='typography-overline'>{t('shared.projects.plot.landMonitoring')}</span>

          <NCCard explainer={<CarbonExplainer />} data-testid='carbon-nca'>
            <NCCardHeader title={t('global.analysis.carbonStorageBg')}>
              {!isSmallScreen && <NCCardAnalysisTypeToggle data-testid='carbon-toggle' />}
            </NCCardHeader>
            <NCCard2SectionLayout>
              <Stack className='items-center pt-4 sm:items-stretch sm:pt-0'>
                {isSmallScreen && <NCCardAnalysisTypeToggle data-testid='carbon-toggle' />}
                <CarbonGraphTile />
              </Stack>
              <CarbonStatsTile />
            </NCCard2SectionLayout>
            <NCCardComplianceFooter logos={COMPLIANCE_LOGOS_CARBON} />
          </NCCard>

          <NCCard explainer={<WaterExplainer />} data-testid='water-nca'>
            <NCCardHeader title={t('global.analysis.water')}>
              {!isSmallScreen && <NCCardAnalysisTypeToggle data-testid='water-toggle' />}
            </NCCardHeader>
            <NCCard2SectionLayout>
              <Stack className='items-center pt-4 sm:items-stretch sm:pt-0'>
                {isSmallScreen && <NCCardAnalysisTypeToggle data-testid='water-toggle' />}
                <WaterHistoricalGraphTile />
              </Stack>
              <WaterHistoricalStatsTile />
            </NCCard2SectionLayout>
            <NCCardComplianceFooter logos={COMPLIANCE_LOGOS_WATER} />
          </NCCard>

          <NCCard explainer={<BiodiversityExplainer />} data-testid='biodiversity-nca'>
            <NCCardHeader
              title={
                <span className='flex flex-row items-center gap-3'>
                  {t('global.analysis.protectedOnFarmHabitat')}
                  <BetaPill />
                </span>
              }
            />
            <NCCard2SectionLayout>
              <BiodiversityGraphTile />
              <BiodiversityStatsTile />
            </NCCard2SectionLayout>
            <NCCardComplianceFooter logos={COMPLIANCE_LOGOS_BIODIVERSITY} />
          </NCCard>
        </Stack>
      </Container>
    </SignedInPageLayout>
  );
};

const PlotActions = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const [plotForDeletion, setPlotForDeletion] = useState<Plot | null>(null);

  const plot = usePlot().data;
  const projectDetail = useProjectDetailById().data;

  const isProjectEditable = getProjectPermissions(projectDetail).includes('write');
  const editDeleteDisabledHint = t('shared.projects.plot.tooltips.disabledEditOrDeletePlot');

  const projectId = projectDetail.id;

  return (
    <>
      <Stack spacing={6} direction='row'>
        <Dropdown>
          <DropdownTrigger asChild>
            <IconButton className='border border-primary-100 text-primary-100' data-testid='nav-overview-more-button'>
              <RiMore2Fill size={24} />
            </IconButton>
          </DropdownTrigger>
          <DropdownContent align='end'>
            <MenuDropdownItemLink
              disabled={!isProjectEditable}
              disabledHint={editDeleteDisabledHint}
              to={buildPath(paths.landSteward.editPlot, { pathParams: { plotId: plot.id, projectId } })}
              state={{
                previousPath: pathname,
              }}
              leftAdornment={<RiEditLine />}
            >
              {/* TODO: MVP-3284 delete shared.plots.overflowMenu.editData */}
              {t('shared.plots.overflowMenu.editPlotData')}
            </MenuDropdownItemLink>
            <MenuDropdownItem
              disabled={!isProjectEditable}
              disabledHint={editDeleteDisabledHint}
              onClick={() => setPlotForDeletion(plot)}
              leftAdornment={<RiDeleteBinLine />}
            >
              {t('shared.plots.overflowMenu.deletePlot')}
            </MenuDropdownItem>
          </DropdownContent>
        </Dropdown>
      </Stack>
      <DeletePlotWithConfirmation
        plot={plotForDeletion}
        onClose={() => setPlotForDeletion(null)}
        onSuccess={() => {
          /**
           * using href instead of navigate to ensure a reload is triggered automatically
           * using navigate + invalidating queries creates a race condition
           */
          window.location.href = buildPath(paths.landSteward.plots, { pathParams: { projectId } });
        }}
      />
    </>
  );
};

type MenuDropdownItemLinkProps = DropdownItemLinkProps & {
  disabledHint: string;
};

const MenuDropdownItemLink: FC<MenuDropdownItemLinkProps> = ({ children, disabledHint, to, ...delegated }) => {
  if (!delegated.disabled) {
    return (
      <DropdownItemLink to={to} {...delegated}>
        {children}
      </DropdownItemLink>
    );
  }

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <DropdownItem {...delegated}>{children}</DropdownItem>
      </TooltipTrigger>
      <TooltipContent sideOffset={5} side='left'>
        {disabledHint}
        <TooltipArrow />
      </TooltipContent>
    </Tooltip>
  );
};

type MenuDropdownItemProps = DropdownItemProps & {
  disabledHint: string;
};

const MenuDropdownItem: FC<MenuDropdownItemProps> = ({ children, disabledHint, ...delegated }) => {
  if (!delegated.disabled) {
    return <DropdownItem {...delegated}>{children}</DropdownItem>;
  }

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <DropdownItem {...delegated}>{children}</DropdownItem>
      </TooltipTrigger>
      <TooltipContent sideOffset={5} side='left'>
        {disabledHint}
        <TooltipArrow />
      </TooltipContent>
    </Tooltip>
  );
};
